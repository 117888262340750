import AppContext, { HomeworkContext, SchoolsContext } from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Col, Image, Row, Spinner } from 'react-bootstrap';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import QuestionsTableHeader from './QuestionsTableHeader';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { searchData } from 'helpers/utils';
import Flex from 'components/common/Flex';

const Questions = () => {
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const navigate = useNavigate();
  const {
    curriculumId,
    gradeId,
    subjectId,
    chapterId,
    topicId,
    sectionId,
    learningObjectiveId,
    mode
  } = useParams();
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;
  const { homework } = useContext(HomeworkContext);
  const { subjects, curriculums } = useContext(SchoolsContext);
  const { config } = useContext(AppContext);
  const [filters, setFilters] = useState({});
  const [fullData, setFulData] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [selectedCurriculum, setSelectedCurriculum] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let fileterdData = [...questions];
    if (filters?.search) {
      fileterdData = searchData(fileterdData, filters.search, [
        ['question', 'ar'],
        ['question', 'en']
      ]);
    }
    setQuestions(fileterdData);
  }, [filters]);

  useEffect(() => {
    console.log(curriculums);
    if (curriculumId) {
      setSelectedCurriculum(
        curriculums?.find(c => `${c.id}` === `${curriculumId}`)
      );
    } else {
      setSelectedCurriculum(subjects);
    }
  }, [curriculumId]);

  useEffect(() => {
    if (
      subjectId &&
      chapterId &&
      topicId &&
      sectionId &&
      learningObjectiveId &&
      selectedCurriculum
    ) {
      let subject = {};
      if (curriculumId) {
        console.log(selectedCurriculum);
        subject = selectedCurriculum?.grades
          ?.find(g => `${g.id}` === `${gradeId}`)
          ?.subjects?.find(s => `${s.id}` === `${subjectId}`);

        console.log(selectedCurriculum, subject);
      } else {
        subject = selectedCurriculum?.find(s => `${s.id}` === `${subjectId}`);
      }

      prepareQuestions(subject);
    }
  }, [selectedCurriculum]);

  useEffect(() => {
    if (homework?.curriculum?.length) {
      let qs = [];
      homework?.curriculum?.map(topic => {
        topic?.sections?.map(section => {
          section?.learningObjectives?.map(learningObjective => {
            learningObjective?.questions?.map(q => {
              qs?.push({
                ...q,
                subjectId: subjectId,
                chapterId: t.chapterId,
                topicId: topic.id,
                sectionId: section.id,
                learningObjectiveId: learningObjective.id
              });
            });
          });
        });
      });
      setSelectedQuestions(qs);
    }
  }, [homework, fullData]);

  const prepareQuestions = subject => {
    let chapter = subject?.chapters?.find(ch => `${ch.id}` === `${chapterId}`);

    let topic = chapter?.topics?.find(t => `${t.id}` === `${topicId}`);
    let section = topic?.sections?.find(s => `${s.id}` === `${sectionId}`);
    let learningObjective = section?.learningObjectives?.find(
      lo => `${lo.id}` === `${learningObjectiveId}`
    );

    if (section?.LearningObjectives) {
      learningObjective = section?.LearningObjectives?.find(
        lo => `${lo.id}` === `${learningObjectiveId}`
      );
    }

    if (mode != 'view') {
      chapter = { ...chapter, subjectId: subject.id };
      topic = { ...topic, chapterId: chapter.id };
      section = { ...section, topicId: topic.id };
      learningObjective = { ...learningObjective, sectionId: section.id };
    }

    if (!subject || !chapter || !topic || !section || !learningObjective) {
      navigate(-1);
      toast.error('Error loading learning objective data');
    }
    setFulData({ subject, chapter, topic, section, learningObjective });
    setQuestions(
      learningObjective.questions?.map(q => ({
        ...q,
        subjectId: subject.id,
        chapterId: chapter.id,
        topicId: topic.id,
        sectionId: section.id,
        learningObjectiveId: learningObjective.id,
        id: learningObjective.id + '.' + q.id
      }))
    );
  };

  let columns = [
    {
      accessor: isRTL ? 'question.ar' : 'question.en',
      Header: t('homework:table.quesiton'),

      Cell: rowData => {
        const { question } = rowData.row.original;
        return (
          <div className="d-flex align-items-center justify-content-center gap-2 direction-ltr text-dark opacity-100">
            {question?.[currentLanguage]}
          </div>
        );
      }
    },

    {
      accessor: 'answer1',
      Header: `${t('homework:table.answer')} 1`,
      headerProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { answers } = rowData.row.original;
        const answer = answers[0];
        return (
          <div className="d-flex align-items-center justify-content-center gap-2 fs-1 text-center direction-ltr w-100 text-dark opacity-100">
            {answer?.[currentLanguage]}
          </div>
        );
      }
    },
    {
      accessor: 'answer2',
      Header: `${t('homework:table.answer')} 2`,
      headerProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { answers } = rowData.row.original;
        const answer = answers[1];
        return (
          <div className="d-flex align-items-center justify-content-center gap-2 fs-1 text-center direction-ltr w-100 text-dark opacity-100">
            {answer?.[currentLanguage]}
          </div>
        );
      }
    },
    {
      accessor: 'answer3',
      Header: `${t('homework:table.answer')} 3`,
      headerProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { answers } = rowData.row.original;
        const answer = answers[2];
        return (
          <div className="d-flex align-items-center justify-content-center gap-2 fs-1 text-center direction-ltr w-100 text-dark opacity-100">
            {answer?.[currentLanguage]}
          </div>
        );
      }
    },
    {
      accessor: 'answer4',
      Header: `${t('homework:table.answer')} 4`,
      headerProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { answers } = rowData.row.original;
        const answer = answers[3];
        return (
          <div className="d-flex align-items-center justify-content-center gap-2 fs-1 text-center direction-ltr w-100 text-dark opacity-100">
            {answer?.[currentLanguage]}
          </div>
        );
      }
    },
    {
      accessor: 'answer5',
      Header: `${t('homework:table.answer')} 5`,
      headerProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const { answers } = rowData.row.original;
        const answer = answers[4];
        return (
          <div className="d-flex align-items-center justify-content-center gap-2 fs-1 text-center direction-ltr w-100 text-dark opacity-100">
            {answer?.[currentLanguage]}
          </div>
        );
      }
    },
    {
      accessor: 'selection',
      Header: t('homework:table.selectedQuestions'),
      headerProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        if (mode === 'view') {
          return;
        }
        const { id } = rowData.row.original;
        const isSelected = !!selectedQuestions?.find(
          q => `${q.id}` === `${id}`
        );
        return (
          <div className="d-flex align-items-center justify-content-center gap-2 fs-1 text-center w-100">
            <div
              className="topic-check-circle mx-2 cursor-pointer"
              onClick={() => {
                if (
                  !isSelected &&
                  selectedQuestions?.length >= homework?.questionsNumber
                ) {
                  toast.error(
                    t('homework:message.onlyQuestionsNumberAllowed', {
                      number: homework?.questionsNumber
                    })
                  );
                  return;
                }
                setSelectedQuestions(
                  isSelected
                    ? selectedQuestions?.filter(q => `${q.id}` !== `${id}`)
                    : [...selectedQuestions, rowData.row.original]
                );
                console.log('Selected Question IDs:', selectedQuestions.map(q => q.id));

              }}
            >
              {isSelected && <FontAwesomeIcon icon={faCheck} size="sm" />}
            </div>
          </div>
        );
      }
    }
  ];

  if (questions.some(question => {
    // Normalize object keys to lowercase
    const normalizedKeys = Object.keys(question?.question || {}).reduce((acc, key) => {
      acc[key.toLowerCase()] = question.question[key];
      return acc;
    }, {});

    // Check for 'image_path_dashboard' in the normalized keys
    return normalizedKeys['image_path_dashboard'];
  })) {
    console.log("questions with image_path_dashboard found:", questions);

    // Dynamically add the 'image' column
    columns.splice(1, 0, {
      accessor: 'image',
      Header: t('homework:table.image'),
      Cell: rowData => {
        const { learningObjectiveId, id } = rowData.row.original;

        return (
          <Image
            width={500}
            src={`https://www.eduhax.com/wp-content/Curriculum/${curriculumId}/${learningObjectiveId.split('.')[0]}/${learningObjectiveId}/${id}${config?.isDark ? '' : '_dashboard'}.png`}
            alt="Learning Objective"
          />
        );
      }
    });

    // Debugging to verify the updated columns
    console.log("Updated columns array:", columns);
  } else {
    console.log("No questions with image_path_dashboard found.");
  }




  if (mode === 'view') {
    columns = columns.filter(c => c.accessor !== 'selection');
  }

  if (loading) {
    return (
      <Flex justifyContent="center">
        <Spinner variant="primary" animation="grow" />
      </Flex>
    );
  }

  return (
    <Row className="gx-3">
      <Col xxl={12} xl={12}>
        <AdvanceTableWrapper
          columns={columns}
          data={questions || []}
          //sortable
          pagination
          perPage={10}
        >
          <Card>
            <Card.Header className="px-0">
              <QuestionsTableHeader
                table
                {...fullData}
                searchValue={filters?.search || ''}
                setSearchValue={value =>
                  setFilters({ ...filters, search: value })
                }
                totalSelectedQuestions={selectedQuestions}
                readOnly={mode === 'view'}
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 fs-3 text-nowrap text-center"
                rowClassName="align-middle white-space-nowrap text-dark"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
              <div className="mt-3 px-2">
                <AdvanceTableFooter
                  rowCount={questions?.length || 1}
                  table
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </div>
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </Col>
    </Row>
  );
};

export default Questions;
